// A React component to wrap our routes and pass the
// properties into the router page components
import { useEffect, useState } from 'react';
import { Route, Routes, useSearchParams, useParams } from 'react-router-dom';
// import EventData from '../../data/demoEvents.json';
import Home from '../../pages/home';
import Traits from '../../pages/traits';
import Wallet from '../../pages/wallet';
import Settings from '../../pages/settings';
import EventSingle from '../../pages/eventSingle';
import TokenSingle from '../../pages/tokenSingle';
import axios from 'axios';
import qs from 'qs';

export default function RouterWrapper() {
  let [searchParams, setSearchParams] = useSearchParams('');
  let router = useParams();
  let { slug } = useParams();
  let [eventData, setEventData] = useState<any>();
  let [nftData, setNftData] = useState<any>({ traits: {} });
  let [experienceTokens, setExperienceTokens] = useState<any>([]);
  let [wallet, setWallet] = useState<any>();
  const [isLoading, setIsLoading] = useState(true);
  const baseUrl = process.env.REACT_APP_API_URL || 'https://router.mmerch.xyz';

  // Get the event data
  useEffect(() => {
    const fetchEvents = async () => {
      const mmerchUuids = ['c33dc40c-69f6-4450-b907-4584fa41a85e', '4979ffd1-dd5a-426b-9771-999424bd8d13', '5e5d1e9f-e875-4534-9c71-6668178f57c1', '0c0e9141-f7c2-45fa-b1f9-13b86884117b']
      const currentUuid = searchParams.get('uuid');
      const endpoint = mmerchUuids.includes(currentUuid || '') 
        ? '/test/check-in/test-events/all'
        : '/test/check-in/events/all';
      console.log('Using endpoint:', `${baseUrl}${endpoint}`);
      try {
        const response = await axios.get(`${baseUrl}${endpoint}`);
        console.log('Events response:', response.data);
        setEventData(response.data.events);
      } catch (error) {
        console.error('Error fetching events:', error);
        // Optionally fallback to demo data
        // setEventData(require('../../data/demoEvents.json'));
      }
    };

    fetchEvents();
  }, [baseUrl, searchParams]);



  // Get the NFT data

  useEffect(() => {
    if (!searchParams.get('uuid') || searchParams.get('uuid')?.length !== 36) {
      // if the query string params aren't a proper GUID
      // just use the test data
      setNftData(require('../../data/data.json').data);
      setIsLoading(false);
    } else {
      axios
        .post(
          `https://garments.mmerch.net/uuidLookup`,
          qs.stringify({ uuid: searchParams.get('uuid') })
        )
        .then((response) => {
          setNftData(response.data.data);
          setIsLoading(false);
          setWallet(response.data.data.wallet);
        });
    }
  }, [searchParams]);

  useEffect(() => {
    //FIXME: do a valid wallet check
    if (nftData.wallet?.length === 42) {
      axios
        .post(
          `https://garments.mmerch.net/experienceTokens`,
          { 
            ww: nftData.wallet,
            guid: nftData.uuid,
          }
        )
        .then((response) => {
          setExperienceTokens(response.data.experienceTokens);
        });
    }
  }, [nftData]);


  return (
    <Routes>
      <Route
        path="/"
        element={
          <Home
            nftData={nftData}
            isLoading={isLoading}
            experienceTokens={experienceTokens}
            wallet={wallet}
          />
        }
      />
      <Route path="/traits" element={<Traits nftData={nftData} />} />
      <Route
        path="/wallet"
        element={<Wallet experienceTokens={experienceTokens} />}
      />
      <Route path="/settings" element={<Settings />} />
      <Route
        path="/eventSingle/:slug"
        element={<EventSingle eventData={eventData} nftData={nftData} wallet={wallet} />}
      />
      <Route
        path="/tokenSingle/:id"
        element={<TokenSingle experienceTokens={experienceTokens} />}
      />
    </Routes>
  );
}
