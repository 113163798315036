import { useState, useEffect } from 'react';
import styles from './home.module.sass';
import axios from 'axios';
import RunGsap from '../../helpers/RunGsap';
import Loader from '../../components/Loader/Loader';
import Subtitle from '../../components/Subtitle/Subtitle';
import EventCard from '../../components/EventCard/EventCard';
import Modal from '../../components/Modal/Modal';
import BulletPoint from '../../components/Module/ModuleText/BulletPoint/BulletPoint';
import Module from '../../components/Module/Module';
import ModuleText from '../../components/Module/ModuleText/ModuleText';
import ButtonContainer, {
  ButtonContainerAlignment,
} from '../../components/ButtonContainer/ButtonContainer';
import Button, { ButtonForm } from '../../components/Button/Button';
import IconArrow from '../../components/Icons/IconArrow';
import ModuleImage from '../../components/Module/ModuleImage/ModuleImage';
import { FETCH_STATUS } from '../../helpers/fetchStatus';
import DemoEvents from '../../data/demoEvents.json';
import { useParams, useSearchParams } from 'react-router-dom';

interface Event {
  image: string;
  path: string;
}

function Home(props: {
  isLoading: boolean;
  nftData: any;
  experienceTokens: any[];
  wallet: string;
}) {
  const isDevelopmentEnv = process.env.NODE_ENV === 'development';
  let nftData = props.nftData;
  let experienceTokens = props.experienceTokens;
  const landingImage = nftData.renderImage;
  const [ModalOpen, setModalOpen] = useState(false);
  const [eventList, setEventList] = useState<Event[]>([]);
  const [status, setStatus] = useState(FETCH_STATUS.IDLE);
  let [searchParams, setSearchParams] = useSearchParams('');

  let uuid = searchParams.get('uuid') || '';

  const mmerchUuids = ['c33dc40c-69f6-4450-b907-4584fa41a85e', '4979ffd1-dd5a-426b-9771-999424bd8d13', '5e5d1e9f-e875-4534-9c71-6668178f57c1', '0c0e9141-f7c2-45fa-b1f9-13b86884117b']

  const fetchEvents = async () => {
    // if (isDevelopmentEnv) {
    //   setEventList(DemoEvents);
    //   setStatus(FETCH_STATUS.SUCCESS);
    // } else 
    if (true) {
      try {
        setStatus(FETCH_STATUS.LOADING);
        const baseUrl = process.env.REACT_APP_API_URL || 'https://router.mmerch.xyz';
        
        // Choose endpoint based on uuid
        const endpoint = mmerchUuids.includes(uuid) 
          ? '/test/check-in/test-events/all'
          : '/test/check-in/events/all';
        
        console.log('Using endpoint:', endpoint);
        const response = await axios.get(`${baseUrl}${endpoint}`);
        
        let events = response.data;
        if (!Array.isArray(events)) {
          events = Object.values(events);
        }
        if (events) {
          setEventList(events[0]);
          setStatus(FETCH_STATUS.SUCCESS);
        }
      } catch (error) {
        console.error('Error fetching events:', error);
        setStatus(FETCH_STATUS.ERROR);
      }
    }
  };
  console.log(eventList);
  useEffect(() => {
    fetchEvents();
  }, [uuid]);

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };
  return (
    <div className="page page-Home">
      <RunGsap />
      <div className={`"section" ${styles.landing}`}>
        <div
          className={`${styles.landing_Image} loadable ${
            props.isLoading ? 'isLoading' : 'isLoaded'
          }`}
        >
          {<img src={landingImage} alt="" loading="lazy" />}
        </div>
        <div className={styles.landing_Text}>
          {props.isLoading ? <h1>Loading...</h1> : <h1>Oh, hi there.</h1>}
        </div>
      </div>
      <div className="sectionGroup">
        <Subtitle
          text="current & upcoming experiences"
          id="events"
          help
          onClick={handleOpenModal}
        />
        {status === FETCH_STATUS.SUCCESS ? (
          eventList.map((event, index) => (
            <EventCard
              key={index}
              hasButton
              background={event.image}
              path={event.path}
            />
          ))
        ) : (
          <Loader />
        )}
      </div>
      {experienceTokens.length > 0 && (
        <>
          <div className="sectionGroup">
            <Subtitle text="Your latest XP" className="forceOpacity" />
            <Module
              hasEvenCorners
              noBottomPadding
              className="card forceOpacity"
            >
              <ModuleText>
                <div className="module-Title">
                  <h1 className="truncate">
                    {experienceTokens[experienceTokens.length - 1].name}
                  </h1>
                  <p className="date truncate">
                    {
                      experienceTokens[experienceTokens.length - 1]
                        .attributes[0].value
                    }
                  </p>
                </div>
              </ModuleText>
              <ButtonContainer align={ButtonContainerAlignment.RightBottom}>
                <Button
                  className="green forceOpacity"
                  form={ButtonForm.Forward}
                  path="/wallet"
                >
                  View all
                  <div className="icon">
                    <IconArrow direction="forward" />
                  </div>
                </Button>
              </ButtonContainer>
              <ModuleImage>
                <img
                  src={experienceTokens[experienceTokens.length - 1].image}
                  alt="nft"
                />
              </ModuleImage>
            </Module>
          </div>
        </>
      )}

      {/* 
        Doing this allows us to precache the images on the home page
        before we get to the traits page...  */}
      <img src={props.nftData?.nftImage} alt="" style={{ display: 'none' }} />
      <Modal
        title="Earn NFTs IRL."
        isOpen={ModalOpen}
        onClose={handleCloseModal}
      >
        <div className="module-Text_List">
          <BulletPoint
            number="1"
            content="Go to mmERCH curated events wearing your hoodie."
          />
          <BulletPoint number="2" content="Tap your NFC chip." />
          <div className="module-Text_BulletPoint animateModule">
            <div className="number">
              <p>3 </p>
            </div>
            <div className="content">
              <p>Get an Experience Token.</p>
              <p>
                No, you can't send these tokens to other wallets. This hoodie is
                your wallet — your wearable wallet — gaining value through
                culture. (Stay informed on{' '}
                <a
                  href="https://twitter.com/mmerch"
                  target="_blank"
                  rel="noreferrer"
                >
                  X
                </a>{' '}
                )
              </p>
            </div>
          </div>
        </div>
      </Modal>
      <div className="page-Bottom" />
    </div>
  );
}

export default Home;
